
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
  overflow-x: hidden;
}

.dashboard {
  position: relative;
}

/* .sidebar {
  background-color: #1185CB;
  padding: 25px 24px 0 16px;
  width: 266px;
  left: 0 !important;
  position: fixed;
} */

.dashboard-content {
  margin-left: 266px;
}

.custom-toggler { display: none;} 
@media(max-width: 768px) {
  .custom-toggler { background: transparent; border: none; cursor: pointer; padding: 5px; display: block; }
}

/* Custom icon styles - using bars for the toggle */
.custom-icon {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px 0;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.side-overlay { display: block; position: absolute; z-index: 9; top: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.66); left: 0; transition: ease 0.5s; }

/* Styling for the icon bars when the button is clicked */
.custom-toggler:focus .custom-icon,
.custom-toggler:hover .custom-icon {
  background-color: #555;
}

.sidebar-mobile { display: none; } 
.sidebar-desktop {
  z-index: 1;
  display: block;
  background-color: #1185CB;
  padding: 25px 24px 0 16px;
  width: 266px;
  position: fixed;
  transition: all 0.5s;
}
@media(max-width: 768px) {
  .side-overlay { display: none; position: absolute; z-index: 9; top: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.66); left: 0; transition: ease 0.5s; }
  .dashboard-content {
    margin-left: 0;
  }
  .sidebar-desktop { display: none;} 

  .sidebar-mobile {
    left: -300px;
    display: block;
    z-index: 10;
    transition: all 0.5s;
    background-color: #1185CB;
  padding: 25px 24px 0 16px;
  width: 266px;
  position: fixed;
  }
}

.item-first a {
  color: #71717A;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.active-item a {
  color: #1185CB;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.navbar {
  border-bottom: 0.5px solid #DAD9D9;
}

.form-bg {
  border-radius: 20px;
  background: #F6F7FB;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 12px 20px 20px 20px;
}
@media(max-width: 768px) {
  .form-bg { border-radius: 0; margin: 0; padding: 12px; overflow: hidden;} 
}
.step-number {
  color: #000;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  border: 1px dashed #000;
  padding: 4px 8px;
  border-radius: 50%;
}

.active-case {
  color: #1185CB;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding-left: 7px;
}

.inactive-case {
  color: #B3B3B3;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding-left: 7px;
}

.form-check11 {
  /* margin-right: 80px; */
  width: 100%;
}

.form-check111 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.form-check111 label {
  margin: 0px 10px;
}

.form-check111 input {
  margin-left: 20px !important;
}

.formis {
  width: 178px;
  height: 26px;
  border-radius: 4px;
  color: #000000;
  border: 1px solid #000000;
}

.Login {
  width: 100%;
  height: 100vh;
  padding: 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media(max-width: 768px) {
  .Login { flex-direction: column; padding: 0;}
  .Login-left { width: 100% !important}
  .Login-right { width: 100% !important; margin: 35px 22px; }
}

.Login-left {
  width: 90%;
  height: 100%;
  background-color: #1185CB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Login-right {
  width: 50%;
  height: 100%;
  background-color: white;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.otp-field {
  display: flex;
}

.otp-field input {
  width: 50px;
  height: 45px;


  text-align: center;
  border-radius: 4px;
  margin: 10px;
  border: 2px solid #1185CB;
  background: #ffffff;
  font-weight: bold;
  color: #000000;
  outline: none;
  transition: all 0.1s;
}

.otp-field input:focus {
  border: 2px solid #a527ff;
  box-shadow: 0 0 2px 2px #a527ff6a;
}

.disabled {
  opacity: 0.5;
}

.space {
  margin-right: 1rem !important;
}

.Login-right h1 {
  color: #000;
font-family: 'Inter', sans-serif !important;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.Login-right label {
  color: #000;
font-family: 'Inter', sans-serif !important;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

input {
  width: 427px;
  border: none;
  border: 1px solid #5F799E !important;
}

.getotp {
  height: 36px;
  width: 124px;
  background-color: #ffffff;
  border: 1px solid #1185CB;
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #1185CB;
text-align: center;
font-family: 'Inter', sans-serif !important;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.Login-right h3 {
  margin-top: 20px;
  color: #000;
font-family: 'Inter', sans-serif !important;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.Login-right p {
  color: #000;
font-family: 'Inter', sans-serif !important;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.logbtn {
  height: 48px;
  width: 121px;
  background-color: #1185CB;
  color: #ffffff;
  border: none;
  text-align: center;
  margin-top: 10px;
}

.rounded {
  border-radius: 10px !important;
}

.bg-gray {
  background-color: #F6F7FB;
}

.card {
  border: none !important;
}

.form2 {
  margin-left: 10px;
  margin-right: 10px;
}

.form1 {
  margin-left: 10px;
  margin-right: 10px;
}

.dar {
  position: relative;
}

.dar input {
  border: .5px solid #000000;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  height: 49px;
}

.dar label {
  border: .5px solid #000000;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  height: 49px;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dar select {
  border: .5px solid #000000;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  height: 49px;
}

.dar img {
  position: absolute;
  right: 20px;
  top: 15px;
}

.hy {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-label {
  height: 60px;
  width: 60px;
  margin-right: 10px;
  font-size: 70px;
  color: #C3E0F2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.add_btn {
  width: 100%;
  margin-top: 30px;
}

.add_btn button {
  width: 90%;
  height: 49px;
  margin-right: 40px;
  padding: 9px, 15px, 9px, 15px;
  border-radius: 8px;
  border: 1.4px solid #1185CB;
  color: #1185CB;
  font-weight: 500;
  background-color: white;
  font-size: 14px;
}

.hg {
  /* margin: 0px 30px; */
}

.darg {
  padding: 20px 10px;
}

.carding {
  display: flex;
  margin: 0px 12px;
  align-items: center;
  justify-content: center;
}

.carding h3 {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
}

/* .carding h4 {
  font-size: 11px;
  color: #1185CB;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
} */

/* .carding img {
  width: 15px;
  height: 15px;
  display: block;
} */
.card-input {
  background-color: transparent;
  color: #000;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 50% !important;
}

.cards {
  box-shadow: 0px 2px 2px 0px #0000001A;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 12px;
}

.save {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.save button {
  width: 230px;
  height: 48px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  background-color: #1185CB;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 30px;
}

.form2 {
  border: none !important;
  border-bottom: 1px solid #000000 !important;
  border-radius: 0px !important;
}

.attach {
  width: 154px !important;
  height: 36px !important;
  padding-top: 9px;
  padding-left: 15px;
  border-radius: 25px !important;
  border: 1.4px solid #1185CB !important;
  color: #1185CB;
  font-weight: 500;
  font-size: 12px;
  background: transparent;
  margin-top: 5px;
  margin-left: 10px;
}

.file-name {
  border-bottom: 1px solid #000;
  width: 100%;
  margin: 0;
  padding-top: 20px;
  margin-left: 16px !important;
}

.dt {
  display: flex;
  align-items: center;
}

.para p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.submit button {
  width: 230px;
  height: 48px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  background-color: #1185CB;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 30px;
}

.prev button {
  width: 230px;
  height: 48px;
  color: #1185CB;
  /* color: #FFFFFF; */
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #1185CB;
  /* background-color: #1185CB; */
  border-radius: 8px;
  /* outline: none;
  border: none; */
  margin-top: 30px;
  background: transparent;
  margin-left: 15px;
}

@media(max-width: 768px) {
  .submit button { max-width: 100%;  }
  .prev button { max-width: 100%; margin-left: 0;}
}

.images span {
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  margin-top: 10px;
  margin: 10px 10px;
}

.img_jan img {
  width: 67px;
  height: 67px;
  margin-right: 20px;
}

.img_san {
  display: flex;
}

.ot {
  margin-left: 20px;
}

.elipse {
  height: 15px;
  width: 15px;
  border: 1px dotted black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 11px;
  font-weight: bold;
}

.hos-doc {
  margin-right: 4rem;
}

.form1 h3 {
  font-size: 24px !important;
}

@media(max-width: 768px) {
  .form1 h3 { 
    font-size: 18px !important;
  }
}

.img_san1 {
  height: 60px;
  width: 60px;
  margin-right: 10px;
  font-size: 70px;
  color: #C3E0F2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.flex {
  display: flex !important;
}

.hos-doc1 {
  justify-content: space-between;
  margin-bottom: 20px;
}

.w-fit {
  width: fit-content;
}


.items-center {
  align-items: center;
}

.pi-box {
  margin-right: 3rem;
  width: 40%;
  border-radius: 15px;
  box-shadow: 0px 2px 2px 0px #0000001A;
  border: 0.5px solid #DBDBDB;
  height: fit-content;
}

@media(max-width: 768px) {
  .pi-box { width: 100%;}
}

.pi-box1 {
  padding: 10px;

}

.pi-box11 {
  justify-content: space-between;
}

.pi-box11 p {
  font-weight: 500;
}

.pi-box12 {
  justify-content: center;
  margin-bottom: 1rem;
}

.pi-box12 .dar {
  border: 1px solid #5F799E;
  border-radius: 6px;
  width: 90%;
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.pi-box12 input {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.pi-box12 .dar p {
  margin-bottom: 0;
  font-size: 11px;
}

.pi-box12 .dar img {
  position: unset;
}

.pi-box13 {
  background-color: #F6F7FB;
  box-shadow: 0px 2px 2px 0px #0000001A;
  border-radius: 15px;
  padding: 15px;
  justify-content: space-between;
}

.pi-box131 {
  text-align: start;
}

.pi-box132 {
  text-align: start;
}

.pi-box132 p {
  font-size: 11px;
  margin-bottom: 7px;
}

.pi-box131 p {
  font-size: 11px;
  margin-bottom: 7px;
}

.pi-box133 img {
  display: block;
  margin-bottom: 10px;
}

.pi-cards {
  justify-content: flex-start;
}

.w-full {
  width: 100% !important;
}

.text-start {
  text-align: start;
}

.justify-between {
  justify-content: space-between !important;
}

.me-10 {
  margin-right: 120px;
}

.pi4-box-w {
  width: 60vmin !important;
}

.clients-pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container {
  max-width: 550px;
  width: 100%;
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clients-pop5 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container5 {
  max-width: 729px;
  width: 100%;
  background: #FFFFFF;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form87 h2 {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.btn_tr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.yes_brn button {
  background: #1185CB1A;
  width: 232px;
  height: 48px;
  border-radius: 8px;
  border: none;
  color: #1185CB;
  font-weight: 700;
  font-size: 18px;
  margin: 0px 5px;
}

.pending_btn button {
  background: #5F799E1A;
  width: 232px;
  height: 48px;
  border-radius: 8px;
  border: none;
  color: #5F799E80;
  font-weight: 500;
  font-size: 18px;
  margin: 0px 5px;
}

.submit_br button {
  background-color: #1185CB;
  width: 200px;
  height: 48px;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  display: block;
  margin: auto;
  margin-top: 50px;
}

.clients-pop1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container1 {
  max-width: 562px;
  width: 100%;
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container1 {
  max-width: 562px;
  width: 100%;
  background: white;
  padding: 100px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.para_o h3 {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-align: center;
  margin-top: 30px;
}

.yes_brn1 button {
  color: #1185CB80;
  font-weight: 400;
}

.pending_btn1 button {
  font-weight: 600;
  color: #5F799E;
}

.pending_btn .pend {
  display: block;
  margin: auto;
  margin-top: 60px;
}

.clients-pop2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container2 {
  max-width: 374px;
  width: 100%;
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clients-pop3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container3 {
  max-width: 562px;
  width: 100%;
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.para_pop1 {
  margin-top: 30px;
}

.para_pop2 {
  margin-top: 30px;
}

.para_pop1 p {
  text-align: center;
}

.para_pop2 p {
  text-align: center;

}

.client-container3 button {
  background-color: #5F799E;
  font-weight: 500;
  color: #ffffff;
}

.clients-pop4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0009;
  z-index: 1000;
  overflow: auto;
}

.client-container4 {
  max-width: 416px;
  width: 100%;
  background: white;
  padding: 50px 20px;
  border-radius: 10px;
  /* margin: 40px auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.client-container4 button {
  background-color: #1185CB;
  font-weight: 500;
  color: #ffffff;
  margin-top: 40px;
}

.chart {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px #0000001A;
  border-radius: 20px;
  padding: 20px;
}

.chart_card {
  box-shadow: 0px 2px 2px 0px #0000001A;
  background-color: #F6F7FB;
  border-radius: 20px;
  padding: 20px;
  /* max-width: 489px; */
  width: 100%;
}

.box_admis {
  background-color: #FFFFFF;
  padding: 12px 15px;
  width: 100%;
  border-radius: 15px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box_admis:hover {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.box_admin {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  padding: 12px 15px;
  width: 100%;
  border-radius: 15px;
  margin: 15px 0px;
}

.box_admin:hover {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.box_admi {
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;

}

.box_admi:hover {
  box-shadow: 0px 4px 4px 0px #00000040;

}

.bix_head {
  margin-left: 10px;
  margin-top: 5px;
}

.bix_head h4 {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.bix_head p {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  opacity: 70%;
  line-height: 8px;
}

.chart img {

  width: 100%;
}

.chart_all {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.chart_all h3 {
  color: #271E4A;
  font-weight: 500;
  font-size: 20px;
}

.chart_all p {
  color: #4BB948;
  font-weight: 400;
  font-size: 14px;
}

.pi-box132 div {
  position: relative;
}

.pi-box132 input {
  position: absolute;
  z-index: -1;
  width: fit-content;
}

.pi-box132 p {
  position: unset;
}

.pi-box132-active input {
  position: unset;
  z-index: 1;
}

.pi-box132-active p {
  position: absolute;
  z-index: -1;
}

.pi-box132 p {
  position: absolute;
  z-index: -1;
}

.dischared {
  width: 100%;
  box-shadow: 0px 2px 2px 0px #0000001A;
  background-color: #F6F7FB;
  border-radius: 20px;
  padding: 25px;
}

.dischared h3 {
  color: #271E4A;
  font-weight: 500;
  font-size: 20px;
}

.thead th {
  color: #271E4A;
  font-weight: 300;
  font-size: 14px;
}

.tbody td {
  color: #271E4A;
  font-size: 14px;
  font-weight: 500;
}

.tbody button {
  background-color: #FE7E7D;
  border: none;
  outline: none;
  padding: 6px 20px;
  color: #ffffff;
}

.docty {
  color: #3F779B;
  font-size: 14px;
}

.df {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #CDFFCC;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inp {
  border: none !important;
  border-bottom: 1px solid #000000 !important;
  outline: none;
  margin-right: 1em;
}

.log {
  background-color: #1185CB;
  width: 121px;
  height: 48px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.otp-field input {
  border: 1px solid #1185CB !important;
}

.Login-left img {
  max-width: 532px;
  width: 100%;
}
@media(max-width: 768px) {
  .Login-left img {
    max-width: 50%;
    width: 100%;
  }
}

.site_data {
  margin: 0px 20px;
}

.box_site {
  box-shadow: 0px 2px 2px 0px #0000001A;
  background: #00C5000D;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box_site1 {
  background: #5F799E1A;

}

.box_site2 {
  background: #FF00000D;

}

.box_site3 {
  background: #1185CB0F;

}

.site_map {
  background-color: #00C500;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site_map1 {
  background-color: #5F799E;
}

.site_map2 {
  background-color: #FF0000;
}

.site_map3 {
  background-color: #1185CB;
}

.site_map p {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;
}

.site_para p {
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
}

.rows h4 {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.rows label {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.rows input {
  /* width: 24px;
  height: 24px;
  border-radius: 5px; */
}

.site_data {
  box-shadow: 0px 2px 2px 0px #0000001A;
  background-color: #FFFFFF;
  padding: 20px;
}

.ty_table table {
  border-radius: 15px;
}

.ty_table thead {
  background-color: #1185CB;
  border-radius: 12px;
}

.ty_table th {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.ty_table tbody td {
  /* color: #0000; */
  font-weight: 400;
  font-size: 16px;
}

.card-input {
  width: 200px;
  border: none !important;
  border-bottom: 1px solid #000 !important;
  outline: none;
}

.approved {
  color: #00C500;
  font-weight: 400;
  font-size: 16px;
}

.pending {
  color: #5F799E;
}

.reject {
  color: #FF0000;
}

.prev_mect {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.prev_mect button { background-color: transparent; border: none; width: 31px; height: 31px; border-radius: 8px; padding: 8px, 9px, 8px, 9px;} 
.prev_mect .active { color: #FFF; width: 31px; height: 31px; border-radius: 8px; padding: 8px, 9px, 8px, 9px; background-color: #1185CB;} 
.prev span,
.next span {
  color: #000000;
  border: 1px solid #1185CB;
  padding: 10px; border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
}

.numb {
  width: 31px;
  height: 31px;
  border-radius: 8px;
  padding: 8px, 9px, 8px, 9px;
  background-color: #1185CB;
  position: relative;
}

.numb p {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transform: translate(-50%, -50%);
}

.ty_table h3 {
  color: #000000;
  font-weight: 500;
  font-size: 24px;
}

.tryyr {
  width: 75%;
  display: block;
  margin: auto;
}

.form-floating textarea {
  width: 100%;
  height: 124px;
}

.choty h4 {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.choty .form-check {
  margin: 12px 0px;
}

.choty .form-check label {
  color: #050505;
  font-weight: 500;
  font-size: 15px;
}

.choty .form-check input {
  width: 20px;
  border-radius: 1px solid #1185CB;
  height: 20px;
}

.sup1 p {
  margin-bottom: 10px;
}

.sup2 b {
  display: block;
  margin-bottom: 10px;
}

.sup-container {
  max-width: 820px;
}

.sup-container .flex {
  justify-content: center;
}

.sup1 {
  width: 35%;
}

.sup2 {
  width: 35%;
}

.submit_br1 button {
  background-color: #fff;
  color: #1185CB;
  border: 2px solid #1185CB;
}

.su-ans {
  color: #1185CB;
  font-weight: 700;
}

.su-box b {
  font-weight: 500;
  font-size: 15px;
}

.su-box p {
  font-size: 11px;
}

.su-box131 {
  margin-right: 15px;
  text-align: start;
}

.su-box132 {
  text-align: start;
}

.bhy button {
  border: 1.4px solid #1185CB;
  color: #1185CB;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 9px, 15px, 9px, 15px;
  width: 124px;
  height: 36px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
}

.bhy button:hover {
  background-color: #1185CB;
  color: #ffffff;
}

.bhy button img {
  width: 15px;
  height: 15px;
}

.col-md-2 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s;
  border: 0.5px solid #B0A8A8;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked~.checkmark {
  background-color: #2196F3;
  box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);
}

.custom-checkbox:checked~.checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.custom-checkbox:checked~.checkmark:after {
  animation: checkAnim 0.2s forwards;
}

.admission-title {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.aadhar-card {
  width: 100%;
  border-radius: 20px;
  padding: 20px 12px;
  border: 0.5px solid #DBDBDB;
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  max-width: 391px;
}
.card-gap { gap: 60px;}

@media(max-width: 768px) {
  .card-gap { gap: 30px;}
}

.aadhar-card-title {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.aadhar-detail-card {
  margin-top: 16px;
  border-radius: 20px;
  background: #F6F7FB;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.aadhar-detail-text {
  margin: 0;
  border-bottom: 1px solid transparent !important;
  color: #000;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 120px;
}

.approve-name {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 190px;
}

.approve-item {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 283px;
}

.approve-btn {
  border-radius: 8px;
  background: #1185CB;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid transparent;
  width: 232px;
  padding: 11px 0;
  transition: all 0.5s;
}

.reject-btn {
  color: #1185CB;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #1185CB;
  background: #FFF;
  width: 232px;
  padding: 11px 0;
  transition: all 0.5s;
}

.approve-btn:hover {
  color: #1185CB;
  background: transparent;
  border: 1px solid #1185CB;
}

.reject-btn:hover {
  color: #fff;
  background: #1185CB;
  border: 1px solid transparent;
}


/* .app-table-head { border-radius: 12px !important; background: #1185CB;} */
.app-table-body {
  border-radius: 12px;
  background: #F6F7FB;
}


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000075;
  z-index: 1000;
}

.loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-container .loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-bottom: 4px solid #1185cb;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-text {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-top: 40px;
}

.modal-text- {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.modal-response-text {
  color: #000;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-top: 40px;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #000;
}

.modal-loader:before,
.modal-loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.modal-loader:after {
  color: #1185CB;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

/* App.css or ImageBoundingBoxPopup.css */

/* Basic page styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
}

/* Button styling */
#bb-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

#bb-button:hover {
  background-color: #0056b3;
}

/* Modal styling */
.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Input field styling */
#bb-input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 22px); /* Adjust width based on padding and border */
}

/* Canvas styling */
canvas {
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* Ensure the modal content is centered */
.ReactModal__Content {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  marginRight: -50% !important;
  transform: translate(-50%, -50%) !important;
  width: 55% !important; /* Adjust based on your preference */
  padding: 20px !important;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.dialog-overlay-p {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.dialog {
  background: white;
  padding: 30px 30px;
  border-radius: 5px;
}

.dialog input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dialog button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0px 0px 0px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}
